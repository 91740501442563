import { useState, useEffect } from 'react';
import { AddRounded, CloseRounded } from '@mui/icons-material';
import {
    Button,
    Drawer,
    Grid,
    IconButton,
    Table,
    TableCell,
    TableRow,
    useTheme,
} from '@mui/material';
import axios from 'axios';

import { BASE_URL } from '../../config';
import LoadingPage from '../LoadingPage';

const UploadForm = ({ closeDrawer }) => {
    const [file, setFile] = useState(null);
    const [name, setName] = useState('');
    const [gsm, setGsm] = useState('');
    const [volume, setVolume] = useState('');
    const [type, setType] = useState('Spectra');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!file) {
            alert('Please add product image');
            return;
        }

        if (gsm === '' || volume === '') {
            alert(`Please add ${gsm === '' ? 'gsm' : 'volume'}`);
            return;
        }

        const formData = new FormData();
        formData.append('image', file);
        formData.append('name', name);
        formData.append('gsm', gsm);
        formData.append('volume', volume);
        formData.append('type', type);

        try {
            await axios.post(`${BASE_URL}/admin/products`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
            });

            closeDrawer();
            // fetchProducts();
            window.location.reload();
        } catch (error) {
            console.error('Error uploading product:', error);
            alert('Error uploading product');
        }
    };

    return (
        <div
            className="DContainer"
            style={{ margin: 'auto', padding: '3rem', boxSizing: 'border-box' }}
        >
            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Grid container spacing={3}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>Product Name:</div>
                            <input
                                style={{
                                    fontSize: '1rem',
                                    height: '1.2rem',
                                    marginBottom: '1rem',
                                    marginTop: '0.2rem',
                                }}
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>GSM:</div>
                            <input
                                style={{
                                    fontSize: '1rem',
                                    height: '1.2rem',
                                    marginBottom: '1rem',
                                    marginTop: '0.2rem',
                                }}
                                type="number"
                                min={1}
                                value={gsm}
                                onChange={(e) => setGsm(e.target.value)}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>Volume (in ml):</div>
                            <input
                                style={{
                                    fontSize: '1rem',
                                    height: '1.2rem',
                                    marginBottom: '1rem',
                                    marginTop: '0.2rem',
                                }}
                                type="number"
                                min={1}
                                value={volume}
                                onChange={(e) => setVolume(e.target.value)}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>Type:</div>
                            <select
                                style={{
                                    fontSize: '1rem',
                                    height: '2rem',
                                    marginTop: '0.2rem',
                                }}
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value="Spectra">Spectra</option>
                                <option value="Normal">Normal</option>
                            </select>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <label>Image:</label>
                        <input type="file" onChange={(e) => setFile(e.target.files[0])} />
                        {file && (
                            <div style={{ padding: '2rem' }}>
                                <img
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '20px',
                                    }}
                                    src={URL.createObjectURL(file)}
                                    alt="image-upload-preview"
                                />
                            </div>
                        )}
                    </Grid>
                </Grid>

                <Button
                    type="submit"
                    variant="contained"
                    style={{ margin: '2rem 0 3rem 0' }}
                >
                    Upload Product
                </Button>
            </form>
        </div>
    );
};

const ProductCard = ({ product, fetchProducts }) => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const toggleDrawer = (val) => setOpen(val);

    let imageUrl = `${BASE_URL}/admin/products/image/${product.id}`;

    const deleteProduct = () => {
        axios
            .delete(`${BASE_URL}/admin/products/${product.id}`, { withCredentials: true })
            .then((res) => {
                console.log({ res });

                toggleDrawer(false);
                fetchProducts();
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                className="ProductCard"
                onClick={() => toggleDrawer(true)}
                style={{ position: 'relative', width: '100%' }}
            >
                <img
                    src={imageUrl}
                    style={{ width: '100%', aspectRatio: 1.4, objectFit: 'cover' }}
                />

                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        padding: '2px 1rem',
                        backgroundColor: 'lightgray',
                    }}
                >
                    {product.volume} ml
                </div>
            </div>

            <Drawer open={open} onClose={() => toggleDrawer(false)} anchor="bottom">
                <IconButton
                    onClick={() => toggleDrawer(false)}
                    style={{ position: 'absolute', right: 0 }}
                >
                    <CloseRounded
                        style={{
                            width: '3rem',
                            height: '3rem',
                            color: 'white',
                        }}
                    />
                </IconButton>

                <Grid
                    container
                    style={{
                        backgroundColor: theme.palette.dbrown.main,
                        padding: '2rem',
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <div
                            style={{
                                width: '100%',
                                padding: '4rem',
                                boxSizing: 'border-box',
                            }}
                        >
                            <img
                                src={imageUrl}
                                style={{ width: '100%', borderRadius: '20px' }}
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            color: 'white',
                            textAlign: 'center',
                        }}
                    >
                        <div>
                            <h1>
                                {product.name} ({product.type})
                            </h1>
                            <Table
                                style={{
                                    fontSize: '1rem',
                                    width: '20rem',
                                    margin: 'auto',
                                }}
                            >
                                <TableRow>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Volume
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                        }}
                                    >
                                        {product.volume} ml
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                        }}
                                    >
                                        GSM
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                        }}
                                    >
                                        {product.gsm}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Finish
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                        }}
                                    >
                                        {product.type}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="warning"
                                style={{
                                    fontSize: '1rem',
                                    margin: '2rem 0.5rem 1rem 0.5rem',
                                    backgroundColor: 'red',
                                }}
                                onClick={deleteProduct}
                            >
                                Delete Product
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Drawer>
        </Grid>
    );
};

const Products = ({ products, fetchProducts }) => {
    let productGroups = {};
    products?.forEach((prod) => {
        let group = productGroups[prod.volume];
        if (group) group.push(prod);
        else group = [prod];

        productGroups[prod.volume] = group;
    });

    console.log({ products, productGroups });

    if (!products) return <></>;

    return (
        <div style={{ marginBottom: '2rem' }}>
            {Object.keys(productGroups)
                .sort()
                .map((group, index) => {
                    return (
                        <div
                            key={'group-ml-' + index}
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <h2 style={{ borderBottom: '1px solid black' }}>
                                {group} ml
                            </h2>
                            <Grid container spacing={4}>
                                {productGroups[group].map((prod, index) => {
                                    return (
                                        <ProductCard
                                            fetchProducts={fetchProducts}
                                            product={prod}
                                            key={'product-' + index}
                                        />
                                    );
                                })}
                            </Grid>
                        </div>
                    );
                })}
        </div>
    );
};

const AdminProducts = () => {
    const [open, setOpen] = useState(false);

    const [products, setProducts] = useState(null);

    const fetchProducts = () => {
        axios
            .get(`${BASE_URL}/admin/products`, { withCredentials: true })
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const toggleDrawer = (value) => {
        setOpen(value);
    };

    return (
        <div className="DContainer">
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button variant="contained" onClick={() => toggleDrawer(true)}>
                    <AddRounded sx={{ mr: 1 }} /> New Cup
                </Button>
            </div>

            <Drawer open={open} onClose={() => toggleDrawer(false)} anchor="bottom">
                <IconButton
                    onClick={() => toggleDrawer(false)}
                    style={{ position: 'absolute', right: 0 }}
                >
                    <CloseRounded
                        style={{
                            width: '3rem',
                            height: '3rem',
                            color: 'black',
                        }}
                    />
                </IconButton>

                <UploadForm
                    closeDrawer={() => toggleDrawer(false)}
                    // fetchProducts={fetchProducts}
                />
            </Drawer>

            {products ? (
                <Products products={products} fetchProducts={fetchProducts} />
            ) : (
                <LoadingPage />
            )}
        </div>
    );
};

export default AdminProducts;
