import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        dbrown: {
            main: '#C69C6D',
            contrastText: '#FFFFFF',
        },
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                    padding: '5px 0px',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    padding: '10px !important',
                },
                root: {
                    padding: '0px !important',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '0px',
                    backgroundColor: '#F1DBC3',
                    color: '#B46206',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#F1DBC3', // Hover color for border
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#F1DBC3', // Border color when focused
                    },
                },
                notchedOutline: {
                    borderColor: '#F1DBC3',
                },
                input: {
                    '&::placeholder': {
                        color: '#C69C6D', // Placeholder text color
                    },
                    color: 'inherit', // Ensures the text color is inherited from the root
                },
            },
        },
    },
});

export default theme;
