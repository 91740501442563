import axios from 'axios';
import { useEffect, useState } from 'react';

import { BASE_URL } from '../../config';
import LoadingPage from '../LoadingPage';

const Query = ({ query }) => {
    console.log({ query });

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <div
                    style={{
                        flex: 1,
                        boxSizing: 'border-box',
                        padding: '10px',
                        border: '1px solid black',
                    }}
                >
                    {query.name}
                </div>
                <div
                    style={{
                        flex: 1,
                        boxSizing: 'border-box',
                        padding: '10px',
                        border: '1px solid black',
                    }}
                >
                    {query.email}
                </div>
                <div
                    style={{
                        flex: 1,
                        boxSizing: 'border-box',
                        padding: '10px',
                        border: '1px solid black',
                    }}
                >
                    {new Date(query?.time?._seconds * 1000).toLocaleString()}
                </div>
            </div>
            <div
                style={{ padding: '10px', border: '1px solid black', textAlign: 'left' }}
            >
                <b>Message: </b>
                {query.message}
            </div>
        </div>
    );
};

const AdminQueries = () => {
    const [loading, setLoading] = useState(true);
    const [queries, setQueries] = useState(null);

    useEffect(() => {
        if (queries?.length > 0) setLoading(false);
    });

    useEffect(() => {
        axios
            .get(`${BASE_URL}/customer/query`)
            .then((res) => {
                setQueries(res.data);
            })
            .catch((err) => {
                console.log({ err });

                setLoading(false);
            });
    }, []);

    if (loading) return <LoadingPage />;

    return (
        <div
            style={{
                width: '100%',
                maxWidth: '1100px',
                boxSizing: 'border-box',
                padding: '1rem',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    fontWeight: 600,
                }}
            >
                <div
                    style={{
                        flex: 1,
                        boxSizing: 'border-box',
                        padding: '10px',
                        border: '1px solid black',
                        backgroundColor: 'rgb(240, 240, 240)',
                    }}
                >
                    Name
                </div>
                <div
                    style={{
                        flex: 1,
                        boxSizing: 'border-box',
                        padding: '10px',
                        border: '1px solid black',
                        backgroundColor: 'rgb(240, 240, 240)',
                    }}
                >
                    Email
                </div>
                <div
                    style={{
                        flex: 1,
                        boxSizing: 'border-box',
                        padding: '10px',
                        border: '1px solid black',
                        backgroundColor: 'rgb(240, 240, 240)',
                    }}
                >
                    Time
                </div>
            </div>
            {queries
                .sort((a, b) => b.time._seconds - a.time._seconds)
                .map((query, index) => {
                    return <Query query={query} key={'query-' + index} />;
                })}
        </div>
    );
};

export default AdminQueries;
