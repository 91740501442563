import Wrapper from './pages/Wrapper';
import LandingPage from './pages/LandingPage';
import ProductsPage from './pages/ProductsPage';

import AdminLogin from './pages/AdminLogin';
import AdminProducts from './pages/AdminProducts';
import AdminWrapper from './pages/AdminWrapper';
import Calculator from './pages/AdminCalculator';
import AdminQueries from './pages/AdminQueries';

const routes = [
    {
        path: '/',
        element: <Wrapper />,
        children: [
            {
                path: '/',
                element: <LandingPage />,
            },
            {
                path: '/products',
                element: <ProductsPage />,
            },
        ],
    },
    {
        path: 'admin',
        element: <AdminWrapper />,
        children: [
            {
                path: '',
                element: <AdminLogin />,
            },
            {
                path: 'products',
                element: <AdminProducts />,
            },
            {
                path: 'calculator',
                element: <Calculator />,
            },
            {
                path: 'queries',
                element: <AdminQueries />,
            },
        ],
    },
];

export default routes;
