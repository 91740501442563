import { useState, useEffect } from 'react';
import { AddRounded, CloseRounded } from '@mui/icons-material';
import {
    Button,
    Drawer,
    Grid,
    IconButton,
    Table,
    TableCell,
    TableRow,
    useTheme,
} from '@mui/material';
import axios from 'axios';

import { BASE_URL } from '../../config';
import LoadingPage from '../LoadingPage';

import './index.css';

const ProductCard = ({ product, fetchProducts }) => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const toggleDrawer = (val) => setOpen(val);

    let imageUrl = `${BASE_URL}/admin/products/image/${product.id}`;

    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                className="ProductCard"
                onClick={() => toggleDrawer(true)}
                style={{ position: 'relative', width: '100%' }}
            >
                <img
                    src={imageUrl}
                    style={{ width: '100%', aspectRatio: 1.4, objectFit: 'cover' }}
                />

                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        padding: '2px 1rem',
                        backgroundColor: 'lightgray',
                    }}
                >
                    {product.volume} ml
                </div>
            </div>

            <Drawer open={open} onClose={() => toggleDrawer(false)} anchor="bottom">
                <IconButton
                    onClick={() => toggleDrawer(false)}
                    style={{ position: 'absolute', right: 0 }}
                >
                    <CloseRounded
                        style={{
                            width: '3rem',
                            height: '3rem',
                            color: 'white',
                        }}
                    />
                </IconButton>

                <Grid
                    container
                    style={{
                        backgroundColor: theme.palette.dbrown.main,
                        padding: '2rem',
                    }}
                >
                    <Grid item xs={12} md={6}>
                        <div
                            style={{
                                width: '100%',
                                padding: '4rem',
                                boxSizing: 'border-box',
                            }}
                        >
                            <img
                                src={imageUrl}
                                style={{ width: '100%', borderRadius: '20px' }}
                            />
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            color: 'white',
                            textAlign: 'center',
                        }}
                    >
                        <div>
                            <h1>
                                {product.name} ({product.type})
                            </h1>
                            <Table
                                style={{
                                    fontSize: '1rem',
                                    width: '20rem',
                                    margin: 'auto',
                                }}
                            >
                                <TableRow>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Volume
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                        }}
                                    >
                                        {product.volume} ml
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                        }}
                                    >
                                        GSM
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                        }}
                                    >
                                        {product.gsm}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Finish
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            border: '1px solid white',
                                            padding: '3px',
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                        }}
                                    >
                                        {product.type}
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </div>
                    </Grid>
                </Grid>
            </Drawer>
        </Grid>
    );
};

const Products = ({ products, fetchProducts }) => {
    let productGroups = {};
    products?.forEach((prod) => {
        let group = productGroups[prod.volume];
        if (group) group.push(prod);
        else group = [prod];

        productGroups[prod.volume] = group;
    });

    console.log({ products, productGroups });

    if (!products) return <></>;

    return (
        <div style={{ marginBottom: '2rem' }}>
            {Object.keys(productGroups)
                .sort()
                .map((group, index) => {
                    return (
                        <div
                            key={'group-ml--' + index}
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <h2 style={{ borderBottom: '1px solid black' }}>
                                {group} ml
                            </h2>
                            <Grid container spacing={4}>
                                {productGroups[group].map((prod, index) => {
                                    return (
                                        <ProductCard
                                            product={prod}
                                            key={'product-' + index}
                                        />
                                    );
                                })}
                            </Grid>
                        </div>
                    );
                })}
        </div>
    );
};

const UserProducts = () => {
    const [products, setProducts] = useState(null);

    const fetchProducts = () => {
        axios
            .get(`${BASE_URL}/admin/products`, { withCredentials: true })
            .then((res) => {
                setProducts(res.data);
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    return (
        <div className="DContainer">
            {products ? (
                <Products products={products} fetchProducts={fetchProducts} />
            ) : (
                <LoadingPage />
            )}
        </div>
    );
};

export default UserProducts;
