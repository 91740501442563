import { useEffect, useState } from 'react';
import {
    Button,
    Drawer,
    Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Paper,
    IconButton,
} from '@mui/material';
import axios from 'axios';
import {
    CheckRounded,
    ChevronRightRounded,
    CloseRounded,
    DeleteRounded,
    DownloadRounded,
} from '@mui/icons-material';

import './index.css';
import { BASE_URL } from '../../config';
import LoadingPage from '../LoadingPage';

// const defaultValues = [
//     { size: '100ml', pieces: 78, packets: 84, costPerPiece: 0.27 },
//     { size: '110ml', pieces: 78, packets: 84, costPerPiece: 0.28 },
//     { size: '130ml', pieces: 78, packets: 77, costPerPiece: 0.31 },
//     { size: '130ml SP', pieces: 80, packets: 60, costPerPiece: 0.365 },
//     { size: '150ml', pieces: 78, packets: 60, costPerPiece: 0.37 },
//     { size: '150ml Long', pieces: 78, packets: 78, costPerPiece: 0 },
//     { size: '150ml SP', pieces: 80, packets: 60, costPerPiece: 0.435 },
//     { size: '170ml SP', pieces: 80, packets: 55, costPerPiece: 0.49 },
//     { size: '200ml', pieces: 80, packets: 50, costPerPiece: 0 },
//     { size: '200ml SP', pieces: 40, packets: 100, costPerPiece: 0.52 },
//     { size: '250ml SP', pieces: 40, packets: 100, costPerPiece: 0.66 },
//     { size: '300ml SP', pieces: 40, packets: 60, costPerPiece: 0.735 },
//     { size: '200ml Ripple', pieces: 25, packets: 60, costPerPiece: 1.22 },
//     { size: '250ml Ripple', pieces: 25, packets: 50, costPerPiece: 1.72 },
// ];

const StyledTableCell = ({ children, ...props }) => {
    return (
        <TableCell {...props} style={{ padding: '5px', border: '1px solid black' }}>
            {children}
        </TableCell>
    );
};

// const removeLeadingZeroes = (str) => {
//     if (typeof str === 'string') {
//         if (/^0+$/.test(str)) {
//             return '0';
//         }
//         // Remove leading zeros
//         str = str.replace(/^0+/, '');
//         if (str.startsWith('.')) return '0' + str;
//     }

//     return str;
// };

const TransparentInput = ({ style, ...props }) => {
    return (
        <input
            {...props}
            style={{
                backgroundColor: 'transparent',
                outline: 'none',
                border: 'none',
                color: 'inherit',
                fontSize: 'inherit',
                textAlign: 'inherit',
                width: 'inherit',
                ...style,
            }}
        />
    );
};

const EditableRow = ({ row, updateRow, newRow = false, itemValues }) => {
    const pieces = row?.pieces || 0;
    const packets = row?.packets || 0;
    const totalCups = pieces * packets;
    const costPerPiece = row?.costPerPiece || 0;
    const amount = totalCups * costPerPiece;
    const boxes = row?.boxes;

    return (
        <TableRow>
            {newRow ? (
                <TableCell align="center">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        Add new <ChevronRightRounded />
                    </div>
                </TableCell>
            ) : (
                <StyledTableCell>
                    <input
                        type="date"
                        value={row?.date}
                        onChange={(event) => {
                            const date = event.target.value;
                            updateRow({ ...row, date });
                        }}
                    />
                </StyledTableCell>
            )}
            <StyledTableCell sx={{ width: '12rem' }}>
                <Autocomplete
                    value={newRow ? null : row}
                    onChange={(event, newValue) => {
                        if (!newValue) updateRow(null);
                        else updateRow({ boxes: 1, ...row, ...newValue });
                    }}
                    options={itemValues}
                    getOptionLabel={(option) => option.size}
                    isOptionEqualToValue={(option, value) => option.size === value.size}
                    renderInput={(params) => <TextField {...params} />}
                    renderOption={(props, option) => <li {...props}>{option.size}</li>}
                />
            </StyledTableCell>
            {!newRow && (
                <>
                    <StyledTableCell align="center" sx={{ width: '6rem' }}>
                        <TransparentInput
                            type="number"
                            value={pieces}
                            onChange={(e) => {
                                console.log(e.target.value);
                                updateRow({ ...row, pieces: e.target.value });
                            }}
                        />
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: '6rem' }}>
                        <TransparentInput
                            type="number"
                            value={packets}
                            onChange={(e) => {
                                console.log(e.target.value);
                                updateRow({ ...row, packets: e.target.value });
                            }}
                        />
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        sx={{ width: '6rem', backgroundColor: 'lightgrey' }}
                    >
                        {totalCups}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: '9rem' }}>
                        <TransparentInput
                            type="number"
                            value={costPerPiece}
                            onChange={(e) => {
                                updateRow({ ...row, costPerPiece: e.target.value });
                            }}
                        />
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        sx={{ width: '6rem', backgroundColor: 'lightgrey' }}
                    >
                        {parseFloat(amount).toFixed(3)}
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: '4rem' }}>
                        <TextField
                            type="number"
                            value={boxes}
                            style={{ width: '100%' }}
                            InputProps={{ inputProps: { min: 1 } }}
                            onChange={(event) => {
                                const boxes = event.target.value;
                                updateRow({ ...row, boxes });
                            }}
                        />
                    </StyledTableCell>
                    <StyledTableCell
                        align="center"
                        sx={{ width: '8rem', backgroundColor: 'lightgrey' }}
                    >
                        {parseFloat(boxes * amount).toFixed(3)}
                    </StyledTableCell>
                </>
            )}
        </TableRow>
    );
};

const ItemsTable = ({ itemValues, fetchItems }) => {
    const [newItem, setNewItem] = useState(null);

    console.log({ itemValues });

    useEffect(() => {
        console.log({ newItem });
    }, [newItem]);

    const addNewItem = () => {
        let item = {
            size: newItem?.size?.trim() || '',
            pieces: newItem?.pieces || 0,
            packets: newItem?.packets || 0,
            costPerPiece: newItem?.costPerPiece || 0,
        };

        if (!item.size.length || !item.pieces || !item.packets || !item.costPerPiece) {
            alert('Incomplete information');
            return;
        }

        axios
            .post(`${BASE_URL}/admin/calculator`, { item }, { withCredentials: true })
            .then((res) => {
                console.log({ res });
                fetchItems();

                setNewItem(null);
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    const deleteItem = (id) => {
        axios
            .delete(`${BASE_URL}/admin/calculator/${id}`, { withCredentials: true })
            .then((res) => {
                console.log({ res });

                fetchItems();
            })
            .catch((err) => {
                console.log({ err });

                fetchItems();
            });
    };

    return (
        <TableContainer>
            <Table sx={{ width: 600, margin: '2rem auto' }}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{ width: '5rem' }}>Size</StyledTableCell>
                        <StyledTableCell sx={{ width: '5rem' }}>Pieces</StyledTableCell>
                        <StyledTableCell sx={{ width: '5rem' }}>Packets</StyledTableCell>
                        <StyledTableCell sx={{ width: '5rem' }}>
                            Total Cups
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: '5rem' }}>
                            Cost (per piece)
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell>
                            <input
                                type="text"
                                placeholder="Add new item"
                                style={{ border: 'none', outline: 'none' }}
                                value={newItem?.size || ''}
                                onChange={(e) => {
                                    const part = { size: e.target.value };
                                    if (newItem) setNewItem({ ...newItem, ...part });
                                    else setNewItem(part);
                                }}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <input
                                type="number"
                                min={1}
                                style={{ border: 'none', outline: 'none', width: '100%' }}
                                value={newItem?.pieces || ''}
                                onChange={(e) => {
                                    const part = { pieces: e.target.value };
                                    if (newItem) setNewItem({ ...newItem, ...part });
                                    else setNewItem(part);
                                }}
                            />
                        </StyledTableCell>
                        <StyledTableCell>
                            <input
                                type="number"
                                min={1}
                                style={{ border: 'none', outline: 'none', width: '100%' }}
                                value={newItem?.packets || ''}
                                onChange={(e) => {
                                    const part = { packets: e.target.value };
                                    if (newItem) setNewItem({ ...newItem, ...part });
                                    else setNewItem(part);
                                }}
                            />
                        </StyledTableCell>
                        <StyledTableCell sx={{ backgroundColor: 'rgb(200,200,200)' }}>
                            <div>{(newItem?.pieces || 0) * (newItem?.packets || 0)}</div>
                        </StyledTableCell>
                        <StyledTableCell>
                            <input
                                type="number"
                                min={0}
                                style={{ border: 'none', outline: 'none', width: '100%' }}
                                value={newItem?.costPerPiece || ''}
                                onChange={(e) => {
                                    const part = { costPerPiece: e.target.value };
                                    if (newItem) setNewItem({ ...newItem, ...part });
                                    else setNewItem(part);
                                }}
                            />
                        </StyledTableCell>
                        <TableCell>
                            <IconButton color="success" onClick={() => addNewItem()}>
                                <CheckRounded />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    {itemValues?.map((row, index) => {
                        return (
                            <TableRow key={'table-row-' + index}>
                                <StyledTableCell>{row.size}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.pieces}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.packets}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.pieces * row.packets}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.costPerPiece}
                                </StyledTableCell>
                                <TableCell>
                                    <IconButton
                                        color="warning"
                                        onClick={() => deleteItem(row.id)}
                                    >
                                        <DeleteRounded />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const Calculator = () => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);

    const [itemValues, setItemValues] = useState(null);

    const [loading, setLoading] = useState(true);

    const toggleDrawer = (val) => {
        setOpen(val);
    };

    const isSortedByDate = (dataArr) => {
        console.log({ dataArr });

        const isSorted = dataArr.every((obj, index) => {
            if (index === 0 || !obj.date) return true;

            const currDate = new Date(obj.date);
            const prevDate = dataArr[index - 1].date
                ? new Date(dataArr[index - 1].date)
                : null;
            return prevDate ? currDate >= prevDate : true;
        });

        console.log({ isSorted });

        return isSorted;
    };

    const convertToCSV = (content) => {
        // Extract column headers
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const columns = [
            { label: 'Date', value: 'date' },
            { label: 'Size', value: 'size' },
            { label: 'Pieces', value: 'pieces' },
            { label: 'Packets', value: 'packets' },
            { label: 'Total Cups', value: 'totalCups' },
            { label: 'Rate', value: 'costPerPiece' },
            { label: 'Amount', value: 'amount' },
            { label: 'Boxes', value: 'boxes' },
            {
                label: 'Total Amount',
                value: 'totalAmount',
            },
        ];
        let csv = columns.map(({ label }) => label).join(columnDelimiter) + lineDelimiter;

        let totalTotalAmount = 0;

        content.forEach((item) => {
            item.totalCups = item.packets * item.pieces;
            item.amount = parseFloat(
                parseFloat(item.costPerPiece * item.totalCups).toFixed(3)
            );
            item.totalAmount = parseFloat(
                parseFloat(item.amount * item.boxes).toFixed(3)
            );

            totalTotalAmount += item.totalAmount;

            columns.forEach((col, index) => {
                if (index > 0) csv += columnDelimiter;
                csv += `"${item[col['value']] ?? ' '}"`;
            });
            csv += lineDelimiter;
        });

        for (let i = 0; i < columns.length - 1; i++) {
            csv += ',';
        }
        csv += `${totalTotalAmount}`;
        csv += lineDelimiter;

        // console.log({ csv });

        // download CSV file
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'Report.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        console.log({ data });

        // Sort based on dates
        const curr = [...data];
        if (!isSortedByDate(curr)) {
            curr.sort((a, b) => new Date(a.date) - new Date(b.date));
            setData(curr);
        }
    }, [data]);

    const fetchItems = () => {
        axios
            .get(`${BASE_URL}/admin/calculator`, { withCredentials: true })
            .then((res) => {
                setItemValues(res.data);
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    useEffect(() => {
        fetchItems();
    }, []);

    useEffect(() => {
        if (itemValues) setLoading(false);
    }, [itemValues]);

    if (loading) return <LoadingPage />;

    return (
        <div className="DContainer">
            <div
                style={{
                    textAlign: 'right',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                }}
            >
                <Button
                    style={{ textTransform: 'none' }}
                    onClick={() => toggleDrawer(true)}
                >
                    View Default Values
                </Button>
            </div>

            <Drawer open={open} onClose={() => toggleDrawer(false)} anchor="bottom">
                <IconButton
                    onClick={() => toggleDrawer(false)}
                    style={{ position: 'absolute', right: 0 }}
                >
                    <CloseRounded
                        style={{
                            width: '3rem',
                            height: '3rem',
                            color: 'black',
                        }}
                    />
                </IconButton>

                <ItemsTable itemValues={itemValues} fetchItems={fetchItems} />
            </Drawer>

            <div>
                <TableContainer sx={{ maxWidth: 1000, margin: 'auto', marginTop: 4 }}>
                    <Table sx={{ minWidth: 900 }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell>Size</StyledTableCell>
                                <StyledTableCell align="center">Pieces</StyledTableCell>
                                <StyledTableCell align="center">Packets</StyledTableCell>
                                <StyledTableCell align="center">
                                    Total Cups
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Cost (per piece)
                                </StyledTableCell>
                                <StyledTableCell align="center">Amount</StyledTableCell>
                                <StyledTableCell align="center">Boxes</StyledTableCell>
                                <StyledTableCell align="center">
                                    Total Amount
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => {
                                return (
                                    <EditableRow
                                        itemValues={itemValues}
                                        row={row}
                                        key={'ed-row-' + index}
                                        updateRow={(newValue) => {
                                            console.log({ newValue });

                                            const curr = [...data];
                                            if (newValue) {
                                                curr.splice(index, 1, newValue);
                                            } else {
                                                curr.splice(index, 1);
                                            }
                                            setData(curr);
                                        }}
                                    />
                                );
                            })}
                            <EditableRow
                                itemValues={itemValues}
                                newRow={true}
                                updateRow={(newValue) => {
                                    if (newValue) {
                                        const curr = [...data, newValue];
                                        setData(curr);
                                    }
                                }}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div
                style={{
                    textAlign: 'right',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    margin: '3rem 0 3rem 0',
                }}
            >
                <Button
                    style={{ textTransform: 'none' }}
                    onClick={() => convertToCSV(data)}
                    variant="contained"
                    color="success"
                >
                    Download CSV <DownloadRounded />
                </Button>
            </div>
        </div>
    );
};

export default Calculator;
