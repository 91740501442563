import { useState, useEffect } from 'react';
import { IconButton, useMediaQuery, Drawer } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import axios from 'axios';
import { ExitToAppRounded } from '@mui/icons-material';
import { CloseRounded, MenuRounded } from '@mui/icons-material';

import './index.css';
import logoText from '../../assets/images/logoText.png';
import LoadingPage from '../LoadingPage';

import { BASE_URL } from '../../config';
import { useUser } from '../../context/UserProvider';

const Wrapper = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [navDrawerOpen, setNavDrawerOpen] = useState(false);

    const hideNav = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(true);

    const { username, setUsername } = useUser();

    const toggleDrawer = (value) => {
        setNavDrawerOpen(value);
    };

    const logout = () => {
        axios
            .post(`${BASE_URL}/admin/auth/logout`, {}, { withCredentials: true })
            .then((res) => {
                console.log(res.data);

                window.location.reload();
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    useEffect(() => {
        axios
            .get(`${BASE_URL}/admin/auth/login-status`, { withCredentials: true })
            .then((res) => {
                console.log({ res });
                setLoading(false);
                setUsername(res.data.username);
            })
            .catch((err) => {
                console.log({ err });
                setLoading(false);
                setUsername('');
            });
    }, []);

    useEffect(() => {
        console.log({ username, url: window.location.pathname });
        if (username === '') {
            if (window.location.pathname !== '/admin') navigate('/admin');
        } else if (username !== null) {
            if (['/admin', '/admin/'].includes(window.location.pathname))
                navigate('/admin/products');
        }
    }, [username]);

    if (loading) return <LoadingPage />;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="NavBar" style={{ width: '100%' }}>
                <nav
                    className=" DContainer"
                    style={{
                        display: 'flex',
                        margin: 'auto',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{ display: 'flex', cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        <img
                            src={logoText}
                            alt="Durokupp Logo"
                            style={{ height: '2rem' }}
                        />
                    </div>

                    {window.location.pathname !== '/admin' && (
                        <>
                            {hideNav ? (
                                <div>
                                    <IconButton onClick={() => toggleDrawer(true)}>
                                        <MenuRounded style={{ color: 'black' }} />
                                    </IconButton>

                                    <Drawer
                                        anchor="top"
                                        open={navDrawerOpen}
                                        onClose={() => toggleDrawer(false)}
                                    >
                                        <IconButton
                                            onClick={() => toggleDrawer(false)}
                                            style={{ position: 'absolute' }}
                                        >
                                            <CloseRounded
                                                style={{
                                                    width: '3rem',
                                                    height: '3rem',
                                                    color: 'black',
                                                }}
                                            />
                                        </IconButton>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    theme.palette.dbrown.main,
                                                textAlign: 'center',
                                                height: '100vh',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '2rem',
                                                    padding: '1rem 0rem',
                                                }}
                                                onClick={() => {
                                                    navigate('/admin/products');
                                                    toggleDrawer(false);
                                                }}
                                            >
                                                Products
                                            </div>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '2rem',
                                                    padding: '1rem 0rem',
                                                }}
                                                onClick={() => {
                                                    navigate('/admin/queries');
                                                    toggleDrawer(false);
                                                }}
                                            >
                                                Queries
                                            </div>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '2rem',
                                                    padding: '1rem 0rem',
                                                }}
                                                onClick={() => {
                                                    navigate('/admin/calculator');
                                                    toggleDrawer(false);
                                                }}
                                            >
                                                Calculator
                                            </div>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '2rem',
                                                    padding: '1rem 0rem',
                                                    color: 'red',
                                                }}
                                                onClick={() => {
                                                    logout();
                                                    toggleDrawer(false);
                                                }}
                                            >
                                                Logout
                                            </div>
                                        </div>
                                    </Drawer>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <div
                                        style={{ marginLeft: '5rem', cursor: 'pointer' }}
                                        onClick={() => navigate('/admin/products')}
                                    >
                                        Products
                                    </div>
                                    <div
                                        style={{ marginLeft: '5rem', cursor: 'pointer' }}
                                        onClick={() => navigate('/admin/queries')}
                                    >
                                        Queries
                                    </div>
                                    <div
                                        style={{ marginLeft: '5rem', cursor: 'pointer' }}
                                        onClick={() => navigate('/admin/calculator')}
                                    >
                                        Calculator
                                    </div>
                                    <div
                                        style={{ marginLeft: '5rem', cursor: 'pointer' }}
                                    >
                                        <IconButton onClick={() => logout()}>
                                            <ExitToAppRounded />
                                        </IconButton>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </nav>
            </div>

            <div style={{ height: '5rem' }}></div>

            <Outlet />
        </div>
    );
};

export default Wrapper;
