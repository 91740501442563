import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import {
    EastRounded,
    EmailOutlined,
    ForestRounded,
    GroupsRounded,
    PhoneOutlined,
    VerifiedRounded,
} from '@mui/icons-material';

import LoadingPage from '../LoadingPage';

import DButton from '../../components/Button';
import vector from '../../assets/images/VisionVector.svg';
import cupGroup from '../../assets/images/CupGroup.png';
import cupStack from '../../assets/images/CupStack.png';
import cup1 from '../../assets/images/Cup1.png';
import cup2 from '../../assets/images/Cup2.png';

import './index.css';
import logo from '../../assets/images/logoText.png';

import { BASE_URL } from '../../config';
import axios from 'axios';

const IconicCup = ({ BeforeIcon, AfterIcon, colorOnHover, text }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);

    const handleMouseLeave = () => setIsHovered(false);

    return (
        <div>
            <div
                style={{ width: '14rem', position: 'relative' }}
                className="Cup"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <svg
                    className="CupIcon"
                    viewBox="0 0 300 300"
                    shapeRendering="geometricPrecision"
                    textRendering="geometricPrecision"
                >
                    <path
                        d="M79.625426,98.751419L100.056754,279.22815h99.886492L221.509648,98.751417l-141.884222.000002Z"
                        transform="translate(0-20.999996)"
                        fill="none"
                        stroke="#3f5787"
                        strokeWidth="4"
                    />
                    <path
                        className="LidBase"
                        d="M79.625426,98.751423L66.00454,98.751419v-15.891033h165.720772v15.891035h-10.215664"
                        transform="translate(0-20.999998)"
                        fill="none"
                        stroke="#3f5787"
                        strokeWidth="4"
                    />
                    <path
                        className="LidTop"
                        d="M100.056754,56.753689h99.886492l21.566402,26.106699-141.884222-.000001l20.431328-26.106698Z"
                        transform="translate(0-20.999997)"
                        fill="none"
                        stroke="#3f5787"
                        strokeWidth="4"
                    />
                </svg>

                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '3rem',
                    }}
                >
                    {isHovered ? (
                        <AfterIcon
                            style={{ width: '3rem', height: '3rem', color: colorOnHover }}
                        />
                    ) : (
                        <BeforeIcon style={{ width: '3rem', height: '3rem' }} />
                    )}
                </div>
            </div>
            <div>{text}</div>
        </div>
    );
};

const QueryForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    return (
        <>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell
                            style={{
                                paddingRight: '2rem',
                                textAlign: 'right',
                            }}
                        >
                            Name
                        </TableCell>
                        <TableCell>
                            <TextField
                                type="text"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value.trimStart().substr(0, 100));
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            style={{
                                paddingRight: '2rem',
                                textAlign: 'right',
                            }}
                        >
                            Email
                        </TableCell>
                        <TableCell>
                            <TextField
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    console.log(e.target.value);
                                    setEmail(e.target.value.split(' ').join(''));
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            style={{
                                paddingRight: '2rem',
                                textAlign: 'right',
                            }}
                        >
                            Query
                        </TableCell>
                        <TableCell>
                            <TextField
                                multiline
                                type="text"
                                rows={2}
                                value={message}
                                onChange={(e) => {
                                    setMessage(
                                        e.target.value.trimStart().substr(0, 1000)
                                    );
                                }}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <DButton
                style={{ marginTop: '3rem' }}
                onClick={() => {
                    console.log('here');
                    if (
                        name.trim().length > 0 &&
                        email.trim().length > 0 &&
                        message.trim().length > 0
                    ) {
                        console.log('here1');
                        axios
                            .post(`${BASE_URL}/customer/query`, { name, email, message })
                            .then((res) => {
                                console.log({ res });
                                alert('Query has been successfully received!');

                                setName('');
                                setEmail('');
                                setMessage('');
                            })
                            .catch((err) => {
                                console.log({ err });
                            });
                    } else {
                        alert('Please fill all the fields');
                    }
                }}
            >
                Submit
            </DButton>
        </>
    );
};

const LandingPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const isScreenSmall = useMediaQuery('(max-width:500px)');
    const isScreenMd = useMediaQuery(theme.breakpoints.down('md'));

    const [loading, setLoading] = useState(true);

    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(img);
            img.onerror = (error) => reject(error);
        });
    };

    const loadFont = () => {
        return new Promise((resolve, reject) => {
            if ('fonts' in document) {
                document.fonts
                    .load('1em Montserrat')
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => reject(err));
            } else reject('Cant load fonts');
        });
    };

    useEffect(() => {
        const images = [cupGroup, cupStack, cup1, cup2];

        Promise.all([...images.map(loadImage), loadFont])
            .then((images) => {
                console.log('All images loaded', images);

                setLoading(false);
            })
            .catch((error) => {
                console.error('An image failed to load', error);
            });
    }, []);

    if (loading) return <LoadingPage />;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid
                container
                style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '600px',
                    marginBottom: '100px',
                    position: 'relative',
                    zIndex: 1,
                }}
                className="DContainer"
                id="landing-main"
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'translateY(-5rem)',
                        minHeight: '600px',
                    }}
                >
                    <div style={{ position: 'absolute' }} className="CupFloat1">
                        <img
                            src={cup1}
                            alt="cup-float-1"
                            style={{
                                width: '200px',
                                transform: 'translateX(-50%)',
                                filter: 'blur(0.8px)',
                            }}
                        />
                    </div>
                    <div style={{ position: 'absolute' }} className="CupFloat2">
                        <img
                            src={cup2}
                            alt="cup-float-2"
                            style={{
                                width: '200px',
                                transform: 'translateX(20%) scale(1.2)',
                            }}
                        />
                    </div>
                </Grid>
                <Grid
                    data-aos="fade-up"
                    item
                    xs={12}
                    md={6}
                    style={{ transform: 'translateY(-5rem)', minHeight: '300px' }}
                >
                    <h1>DUROKUPP</h1>
                    <p
                        style={{
                            lineHeight: '2rem',
                            paddingLeft: '1rem',
                            paddingRight: '1rem',
                        }}
                    >
                        Revolutionizing the paper cup industry with a perfect blend of
                        innovation and sustainability. Providing easy, disposable cup
                        solutions that simplify the service needs of businesses and
                        individuals
                    </p>
                    <DButton
                        endIcon={<EastRounded />}
                        style={{ marginTop: '2rem' }}
                        onClick={() => {
                            navigate('products');
                        }}
                    >
                        Browse Products
                    </DButton>
                </Grid>
            </Grid>

            <div
                style={{
                    textAlign: 'left',
                    position: 'relative',
                    backgroundColor: theme.palette.dbrown.main,
                    width: '100vw',
                }}
                id="landing-vision"
            >
                <img
                    src={vector}
                    style={{
                        width: '100%',
                        transform: 'translateY(-99%)',
                        position: 'absolute',
                    }}
                    alt="vector-curve-vision"
                />
                <div
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                    className="DContainer"
                    style={{
                        margin: 'auto',
                        boxSizing: 'border-box',
                        padding: isScreenMd
                            ? '4rem 1rem 8rem 1rem'
                            : '4rem 2rem 5rem 2rem',
                        color: 'white',
                        position: 'relative',
                    }}
                >
                    <h1>Vision</h1>
                    <h3>Leading the Global Wave of Sustainable Serving Solutions</h3>
                    <p style={{ lineHeight: '2rem' }}>
                        Our vision at Durokupp is clear - to become a leading global
                        provider of innovative, eco-friendly paper cups. We aim to
                        redefine how businesses approach serving solutions, advocating for
                        sustainability without compromising style.
                    </p>

                    <img
                        src={cupGroup}
                        style={{
                            width: isScreenSmall ? '100%' : '400px',
                            position: 'absolute',
                            transform: 'translateY(50%)',
                            right: 0,
                            bottom: 0,
                        }}
                        alt="cup-group-vision"
                    />
                </div>
            </div>

            <div
                style={{
                    textAlign: 'left',
                    position: 'relative',
                    width: '100vw',
                }}
                id="landing-mission"
            >
                <div
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-center"
                    className="DContainer"
                    style={{
                        margin: 'auto',
                        boxSizing: 'border-box',
                        padding: isScreenMd
                            ? '8rem 1rem 5rem 1rem'
                            : '4rem 2rem 5rem 2rem',
                        color: theme.palette.dbrown.main,
                        position: 'relative',
                    }}
                >
                    <h1>Mission</h1>
                    <h3>Serving Convenience in Every Cup</h3>
                    <p style={{ lineHeight: '2rem', color: 'black' }}>
                        Our mission is simple yet powerful. We are on a mission to provide
                        easy, disposable cup solutions that simplify the service needs of
                        businesses and individuals, particularly when catering to large
                        groups. We aim to streamline your operations with our reliable and
                        convenient paper cups.
                    </p>
                </div>
            </div>

            <div
                className="DContainer ThreeCups"
                data-aos="fade-up"
                data-aos-anchor-placement="top-center"
            >
                <IconicCup
                    BeforeIcon={GroupsRounded}
                    AfterIcon={GroupsRounded}
                    colorOnHover="#ee9090"
                    text="Customer Satisfaction"
                />
                <IconicCup
                    BeforeIcon={ForestRounded}
                    AfterIcon={ForestRounded}
                    colorOnHover="#37bf37"
                    text="Eco Friendly"
                />
                <IconicCup
                    BeforeIcon={VerifiedRounded}
                    AfterIcon={VerifiedRounded}
                    colorOnHover="#6189d9"
                    text="Top-notch Quality"
                />
            </div>

            <div
                className="DContainer"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    marginTop: '5rem',
                }}
                id="landing-contact-us"
            >
                <h1 style={{ width: '100%' }}>Contact Us</h1>
                <div className="ContactUsBody">
                    <div
                        style={{
                            width: '200px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '2rem 0rem',
                        }}
                    >
                        <img
                            src={cupStack}
                            style={{ width: '100px' }}
                            alt="cup-stack-contactus"
                        />
                    </div>

                    <div style={{ margin: '2rem 0rem' }} className="QueryForm">
                        <form style={{ width: '100%' }}>
                            <QueryForm />
                        </form>
                    </div>
                </div>
            </div>

            <div
                style={{
                    backgroundColor: theme.palette.dbrown.main,
                    width: '100vw',
                    marginTop: '100px',
                    padding: '20px 0px 20px 0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                }}
            >
                <Grid container className="DContainer" spacing={4}>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                            textAlign: 'left',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <img
                            src={logo}
                            style={{ width: 'min(300px, 80%)', padding: '1rem 0 1rem 0' }}
                        />

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontSize: '0.9rem',
                            }}
                        >
                            <a
                                href="mailto:customercare@durokupp.com"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <EmailOutlined style={{ fontSize: '0.9rem' }} />
                                &nbsp; customercare@durokupp.com
                            </a>
                            <a
                                href="tel:+918837768006"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <PhoneOutlined style={{ fontSize: '0.9rem' }} />
                                &nbsp; +91 88377 68006
                            </a>
                        </div>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ textAlign: 'left', lineHeight: '1.5rem' }}
                    >
                        <b>Address</b>
                        <br />
                        Raj Box Mfg.Co. <br />
                        P-2 Textile Colony <br />
                        Ludhiana
                        <br />
                        141001
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        style={{ textAlign: 'left', lineHeight: '1.5rem' }}
                    >
                        <b>Quick Links</b>
                        <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>
                                <a href="/products">Products</a>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default LandingPage;
