import { useState } from 'react';
import { IconButton, useMediaQuery, Drawer } from '@mui/material';
import logoText from '../../assets/images/logoText.png';

import './index.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { CloseRounded, MenuRounded } from '@mui/icons-material';

const Wrapper = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [navDrawerOpen, setNavDrawerOpen] = useState(false);

    const hideNav = useMediaQuery(theme.breakpoints.down('md'));

    const scrollTo = (sectionId) => {
        if (navDrawerOpen) toggleDrawer(false);

        const element = document.getElementById(sectionId);
        element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    const toggleDrawer = (value) => {
        setNavDrawerOpen(value);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="NavBar" style={{ width: '100%' }}>
                <nav
                    className=" DContainer"
                    style={{
                        display: 'flex',
                        margin: 'auto',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{ display: 'flex', cursor: 'pointer' }}
                        onClick={() => {
                            if (window.location.pathname === '/')
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            else navigate('/');
                        }}
                    >
                        <img
                            src={logoText}
                            alt="Durokupp Logo"
                            style={{ height: '2rem' }}
                        />
                    </div>

                    {window.location.pathname === '/' && (
                        <>
                            {hideNav ? (
                                <div>
                                    <IconButton onClick={() => toggleDrawer(true)}>
                                        <MenuRounded style={{ color: 'black' }} />
                                    </IconButton>

                                    <Drawer
                                        anchor="top"
                                        open={navDrawerOpen}
                                        onClose={() => toggleDrawer(false)}
                                    >
                                        <IconButton
                                            onClick={() => toggleDrawer(false)}
                                            style={{ position: 'absolute' }}
                                        >
                                            <CloseRounded
                                                style={{
                                                    width: '3rem',
                                                    height: '3rem',
                                                    color: 'black',
                                                }}
                                            />
                                        </IconButton>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    theme.palette.dbrown.main,
                                                textAlign: 'center',
                                                height: '100vh',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '2rem',
                                                    padding: '1rem 0rem',
                                                }}
                                                onClick={() => navigate('products')}
                                            >
                                                Products
                                            </div>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '2rem',
                                                    padding: '1rem 0rem',
                                                }}
                                                onClick={() => scrollTo('landing-vision')}
                                            >
                                                Vision
                                            </div>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '2rem',
                                                    padding: '1rem 0rem',
                                                }}
                                                onClick={() =>
                                                    scrollTo('landing-mission')
                                                }
                                            >
                                                Mission
                                            </div>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '2rem',
                                                    padding: '1rem 0rem',
                                                }}
                                                onClick={() =>
                                                    scrollTo('landing-contact-us')
                                                }
                                            >
                                                Contact Us
                                            </div>
                                        </div>
                                    </Drawer>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <div
                                        style={{ marginLeft: '5rem', cursor: 'pointer' }}
                                        onClick={() => navigate('products')}
                                    >
                                        Products
                                    </div>
                                    <div
                                        style={{ marginLeft: '5rem', cursor: 'pointer' }}
                                        onClick={() => scrollTo('landing-vision')}
                                    >
                                        Vision
                                    </div>
                                    <div
                                        style={{ marginLeft: '5rem', cursor: 'pointer' }}
                                        onClick={() => scrollTo('landing-mission')}
                                    >
                                        Mission
                                    </div>
                                    <div
                                        style={{ marginLeft: '5rem', cursor: 'pointer' }}
                                        onClick={() => scrollTo('landing-contact-us')}
                                    >
                                        Contact Us
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </nav>
            </div>

            <div style={{ height: '5rem' }}></div>

            <Outlet />
        </div>
    );
};

export default Wrapper;
