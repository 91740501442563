import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Button, TextField } from '@mui/material';

import { useUser } from '../../context/UserProvider';

import { BASE_URL } from '../../config';

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const { setUsername: setAdmin } = useUser();

    const handleSubmit = async (e) => {
        e.preventDefault();

        axios
            .post(
                `${BASE_URL}/admin/auth/login`,
                {
                    username,
                    password,
                },
                { withCredentials: true }
            )
            .then((res) => {
                setAdmin(username);
                console.log({ res });
            })
            .catch((err) => {
                console.log({ err });
            });
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 10rem)',
            }}
        >
            <form onSubmit={handleSubmit}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                    <p style={{ textAlign: 'left' }}>Username</p>
                    <TextField
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value?.trim())}
                        style={{ width: 'min(90vw, 300px)' }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                    <p style={{ textAlign: 'left' }}>Password</p>
                    <TextField
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value?.trim())}
                        style={{ width: 'min(90vw, 300px)' }}
                    />
                </div>
                <Button type="submit" style={{ marginTop: '2rem' }} variant="contained">
                    Login
                </Button>
            </form>
        </div>
    );
};

export default LoginForm;
