import { Button } from '@mui/material';

import './index.css';

const DButton = ({ children, style, variant, ...props }) => {
    return (
        <Button
            className="DButton"
            color="dbrown"
            variant={variant ?? 'contained'}
            style={{
                ...style,
                textDecoration: 'none',
                textTransform: 'none',
            }}
            disableElevation
            {...props}
        >
            {children}
        </Button>
    );
};

export default DButton;
