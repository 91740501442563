import { useContext, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';

import routes from './routes';
import './App.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
    const router = useRoutes(routes);

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        AOS.init();
    }, []);

    return <div className="App">{router}</div>;
}

export default App;
