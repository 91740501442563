import { useTheme } from '@emotion/react';
import { CircularProgress } from '@mui/material';

const LoadingPage = () => {
    const theme = useTheme();

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1000,
                backgroundColor: '#fff8e3',
            }}
        >
            <CircularProgress
                style={{
                    color: theme.palette.dbrown.main,
                    width: '60px',
                    height: '60px',
                }}
            />
        </div>
    );
};

export default LoadingPage;
